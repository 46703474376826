<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <label class="form-label ml-3 font-weight-700 fs-md color-black">Thông tin lịch test</label>
        </div>
        <div class="card card-custom gutter-b example example-compact border border-gray mb-3">
          <div class="card-body">
            <div class="form-row mt-2 mb-3">
              <div class="col-md-6">
                <label>Họ và tên khách hàng</label>
                <el-input type="text" placeholder="Họ tên" v-model="customer.test_input_schedule.name" disabled/>
              </div>
              <div class="col-md-6">
                <label>Số điện thoại khách hàng</label>
                <el-input type="text" placeholder="Họ tên" v-model="customer.test_input_schedule.phone" disabled/>
              </div>
            </div>
            <test-input :checkExist="true"
                        :testInputSchedule="customer.test_input_schedule"
                        :checkDisable="true"
                        @infoTestInput="handleInfoTestInput"
                        :runCheckSlot="showButtonHuyLichDoiLich"
                        v-if="renderTestInput && !conditionIsOnlyGeneral"></test-input>
            <div class="form-row" v-if="conditionIsOnlyGeneral">
              <p style="color: red; font-weight: bold; margin-left: 4px">Khách hàng chưa được đặt lịch test
                speaking!</p>
              <div class="col-md-12 mb-3">
                <button type="button" @click="openDialogTestInputSchedule('them-lich-speaking')"
                        class="btn btn-success float-left mt-1 ">
                  <i class="el-icon-date"></i>
                  <span class="ml-2">Đặt lịch test speaking</span>
                </button>
              </div>
            </div>
            <div class="form-row" v-else>
              <div class="col-md-12 mb-3">
                <button type="button" @click="openDialogTestInputSchedule('dat-lich')"
                        class="btn btn-success float-left mt-3 ml-3"
                        v-if="[DA_TEST_SPEAKING,DA_TRA_KET_QUA,HUY_LICH,CHO_CHAM_DIEM].includes(customer?.test_input_schedule?.status)">
                  <i class="fa fa-undo"></i>
                  Đặt lại
                </button>
                <button type="button" @click="openDialogTestInputSchedule('doi-lich')" :disabled="loading"
                        class="btn btn-primary float-right mt-3 ml-3"
                        v-if="[DA_DAT_LICH,DA_TEST_GENERAL].includes(customer?.test_input_schedule?.status)&&!untilTestTime">
                  <i class="fa fa-repeat"></i>
                  Đổi lịch
                </button>
                <button type="button" @click="cancelTestInputSchedule" class="btn btn-danger float-right mt-3 ml-3"
                        v-if="[DA_DAT_LICH,DA_TEST_GENERAL].includes(customer?.test_input_schedule?.status)&&!untilTestTime">
                  <i class="fal fa-trash mr-1"></i>
                  Hủy lịch
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showBaiTestTracNghiem">
          <div class="row mt-6">
            <label
                class="form-label ml-3 font-weight-700 fs-md color-black">{{ conditionIsOnlyGeneral ? 'Bài test general' : 'Bài test trắc nghiệm' }}</label>
          </div>
          <div class="card card-custom gutter-b example example-compact border border-gray mb-3">
            <div class="card-body">
              <p>
                {{ conditionIsOnlyGeneral ? 'Gửi link hoặc quét mã QR tới khách hàng để tiến hành làm bài test general:' : 'Gửi link hoặc quét mã QR cho khách hàng làm bài trước để giúp tiết kiệm thời gian cho buổi kiểm tra đầu vào.' }}
              </p>
              <div class="mb-2">Link website:</div>
              <div class="form-row">
                <div class="col-md-10" @click="copyUrl">
                  <el-input onCopy="return false" disabled placeholder="Please input" v-model="urlTestInput"
                            id="url"></el-input>
                </div>
                <div class="col-md-2">
                  <el-tooltip content="Sao chép đường dẫn" placement="top">
                    <button @click="copyUrl" class="btn btn-default ml-1" title="Sao chép đường dẫn"><i
                        class="el-icon-document-copy v-size--small"></i></button>
                  </el-tooltip>
                </div>
              </div>
              <div class="mb-2 mt-3">Mã QR</div>
              <div style="width: 110px ;height: 110px ; border: solid 1px "
                   class="d-flex justify-content-center align-items-center">
                <qr-code :text="getUrlCopy" :size="100">
                </qr-code>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-6">
          <label class="form-label ml-3 font-weight-700 fs-md color-black">Trạng thái ca test</label>
        </div>
        <div class="row ml-12">
          <label class="status-test-input ml-3 pt-2 pr-4 pb-2 pl-4 badge badge-large badge-primary"
                 style="background-color: #132e4887">{{ customer.status }} </label>
        </div>
        <div class="row ml-12 mt-2" v-if="customer?.test_input_schedule?.canceller_name || customer?.test_input_schedule?.reason_cancel">
          <p class="ml-3" style="color: red;" v-if="customer?.test_input_schedule?.reason_cancel">
            {{ customer?.test_input_schedule?.reason_cancel }}
          </p>
          <p class="ml-3" style="color: red;" v-else>
            {{ customer.test_input_schedule.canceller_name }} đã hủy lịch test đầu vào lúc
            {{ formatDate(customer.test_input_schedule.canceled_at) }}
          </p>
        </div>
      </div>
      <div class="col-md-8">
        <div class="container mt-xl-0 mt-4">
          <h3 class="form-label ml-3 font-weight-700 fs-md color-black">Kết quả bài test</h3>

          <div class="card" style="background-color: #F6F6F6;"
               v-if="+customer?.test_input_schedule?.center_id !== Cambridge_Mentor_ID">
            <div class=" content_result  p-4     ">
              <div v-if="resultTests.length>0">
                <h3>Điểm và nhận xét</h3>
                <div v-for="(resultTest,index) in resultTests" :key="index">
                  <CommentsTest :resultTest=resultTest :testIELTS="testIELTS"></CommentsTest>
                </div>
                <OveralTest v-if="(testIELTS && resultTests.length == 4 ) ||((!testIELTS && resultTests.length == 2 ))"
                            :pointOverall="pointOverall" :testIELTS="testIELTS"></OveralTest>
                <div v-if="suggestedCourse || suggestedCourse != ''">
                  <h3>Khóa học gợi ý</h3>
                  <div class="bg-white rounded-sm p-5" style="font-size: 16px">
                    {{ suggestedCourse }}
                  </div>
                </div>
              </div>
              <div v-else>
                <p class="mb-0" style="font-size: 16px">Chưa có kết quả.</p>
              </div>
            </div>
          </div>

          <div v-else class="card" style="background-color: #F6F6F6;">
            <div class=" content_result  p-4     ">
              <div v-if="resultTests.length>0">
                <div class="card p-4 mb-4" v-for="(result,index1) in resultTests" :key="index1">
                  <ResultTestDetailChild :result="result"></ResultTestDetailChild>
                </div>
                <div class="card p-4 mb-4" v-for="(item,index2) in commentAndConclusion" :key="index2">
                  <comment-and-conclusion :comment="item"></comment-and-conclusion>
                </div>
              </div>
              <div v-else>
                <p class="mb-0" style="font-size: 16px">Chưa có kết quả.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible"
               :before-close="()=>{statusCreateTestInput=STATUS_CREATE}" :close-on-click-modal="false"
               :show-close="false" :destroy-on-close="true">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }" v-if="statusCreateTestInput === STATUS_CREATE">
        <form class="form appointment-form" @submit.prevent="handleSubmit(submit)" autocomplete="off">
          <el-row :gutter="10" class="pr-2 pl-2">
            <reschedule-test-input v-if="dialogFormVisible" :checkExist="checkExistTestInputSchedule"
                                   :testInputSchedule="customer.test_input_schedule"
                                   :showTestInput="true"
                                   @infoTestInput="handleInfoTestInput">
            </reschedule-test-input>
          </el-row>
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <button :disabled="disableSubmit" class="btn btn-primary float-right mt-3 ml-3">
                <i v-if="disableSubmit" class="el-icon-loading"></i>Xác nhận
              </button>
              <button type="button" @click="closeDialog" class="btn btn-secondary float-right mt-3 ml-3">
                Hủy bỏ
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div v-else>
        <LoadingCreateTestInput v-if="statusCreateTestInput === STATUS_PENDING" :idTaskCreating="idTaskCreating"
                                @statusCreateTestInput="handleStatusCreateTestInput"
                                :count-time="countTime"></LoadingCreateTestInput>
        <succes-create-input v-if="statusCreateTestInput === STATUS_TO_DO" @closeModal="dialogFormVisible = false"
                             @reloadPage=" getTestInputSchedule();" :idCustomer="idCustomer"
                             :showDetailButton="false" :successCreateData="successCreateData"></succes-create-input>
        <cancel-create-input v-if="statusCreateTestInput === STATUS_FAlURE || statusCreateTestInput === STATUS_NOT_ACTIVE"
                             @closeModal="dialogFormVisible = false"></cancel-create-input>
        <overload-create-test-input v-if="statusCreateTestInput === STATUS_OVER_LOADING"
                                    @closeModal="dialogFormVisible = false"></overload-create-test-input>
      </div>
    </el-dialog>
    <div>
      <el-dialog
          title="Xác nhận khách hàng tham gia test speaking"
          :visible.sync="confirmSpeaking"
           :width="isMobile()?'90%':'30%'"
          :close-on-click-modal="false"
          :show-close="false"
      >
        <p>{{`Khách hàng ${dataConfirmSpeaking.name} có một lịch test speaking với giảng viên vào lúc:`}}</p>
        <p style="color: #5E00B6;font-weight: bold">{{`Thời gian: ${dataConfirmSpeaking.time}`}}</p>
        <p>Vui lòng xác nhận nếu khách hàng có tham gia hoặc Hủy lịch nếu khách hàng không còn nhu cầu.</p>
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-danger mr-3" @click="confirmTestSpeaking(false)">Hủy lịch</button>
          <button type="button" class="btn btn-success" @click="confirmTestSpeaking(true)">Khách hàng có tham gia</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import TestInput from "@/views/pages/customer/test-input";
import {
  CANCEL_TEST_INPUT_SCHEDULE,
  CREATE_TEST_INPUT_SCHEDULE, GET_RESULT_EXAM,
  GET_TEST_INPUT_SCHEDULE,
  UPDATE_TEST_INPUT_SCHEDULE
} from "@/core/services/store/customer/testInput.module";
import moment from 'moment-timezone';
import {
  CHO_CHAM_DIEM,
  DA_DAT_LICH,
  DA_TEST_GENERAL,
  DA_TEST_SPEAKING,
  DA_TRA_KET_QUA,
  HUY_LICH
} from "@/core/config/customer/testInputSchedule";
import Swal from "sweetalert2";
import {ECOACH} from "../../../core/config/center/centerOption";
import {ResultTest, toRoundingMark} from "@/views/pages/customer/models/result_test";
import {TYPE_TEST_BY_KEY} from "@/core/config/testInput/testInputOption";
import CommentsTest from "@/views/pages/customer/components/CommentsTest";
import OveralTest from "@/views/pages/customer/components/OveralTest";
import RescheduleTestInput from "@/views/pages/customer/reschedule-test-input";
import {
  CHECK_STATUS,
  CONFIRM_CUSTOMER_COME_TEST,
  IS_TIME_TO_CONFIRM
} from "../../../core/services/store/customer/testInput.module";
import CancelCreateInput from "./components/CancelCreateInput.vue";
import LoadingCreateTestInput from "./components/LoadingCreateTestInput.vue";
import SuccesCreateInput from "./components/SuccesCreateInput.vue";
import OverloadCreateTestInput from "./components/OverloadCreateTestInput.vue";
import {
  STATUS_CREATE,
  STATUS_FAlURE, STATUS_NOT_ACTIVE,
  STATUS_OVER_LOADING,
  STATUS_PENDING,
  STATUS_TO_DO
} from "../../../core/config/testInput/testInputOption";
import {
  ERROR_CODE_ASSIGN_FORM_EDIT,
  ERROR_CODE_UPDATE_IN_TASK_CANCEL
} from "../../../core/config/testInput/errorCodeDefine";
import ResultTestDetailChild from "./components/ResultTestDetailChild.vue";
import CommentAndConclusion from "./components/CommentAndConclusion.vue";
import {mapGetters} from "vuex";
import tr from "vue2-datepicker/locale/es/tr";


export default {
  name: "detail-test-input-schedule",
  components: {
    CommentAndConclusion,
    ResultTestDetailChild,
    OverloadCreateTestInput,
    SuccesCreateInput,
    LoadingCreateTestInput,
    CancelCreateInput,
    RescheduleTestInput, OveralTest, CommentsTest, TestInput
  },
  data() {
    return {
      customer: {
        test_input_schedule: {}
      },
      loading: false,
      showButtonHuyLichDoiLich: false,
      renderTestInput: false,
      testInput: {},
      dialog: {
        title: ''
      },
      dialogFormVisible: false,
      checkExistTestInputSchedule: false,
      urlTestInput: this.getUrlCopy,
      resultTests: [],
      testIELTS: false,
      suggestedCourse: null,
      disableSubmit: false,
      pointOverall: null,
      DA_DAT_LICH: DA_DAT_LICH,
      DA_TEST_GENERAL: DA_TEST_GENERAL,
      DA_TEST_SPEAKING: DA_TEST_SPEAKING,
      CHO_CHAM_DIEM: CHO_CHAM_DIEM,
      DA_TRA_KET_QUA: DA_TRA_KET_QUA,
      HUY_LICH: HUY_LICH,
      statusCreateTestInput: STATUS_CREATE,
      idTaskCreating: null,
      STATUS_TO_DO: STATUS_TO_DO,
      STATUS_PENDING: STATUS_PENDING,
      STATUS_FAlURE: STATUS_FAlURE,
      STATUS_OVER_LOADING: STATUS_OVER_LOADING,
      STATUS_CREATE: STATUS_CREATE,
      idCustomer: null,
      countTime: 60,
      Cambridge_Mentor_ID: 530,
      commentAndConclusion: [],
      status: '',
      confirmSpeaking: false,
      dataConfirmSpeaking: {
        name:'',
        time: ''
      },
      successCreateData: {},
      STATUS_NOT_ACTIVE:STATUS_NOT_ACTIVE
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách khách hàng", route: "customer-list"},
      {title: "Chi tiết Ca test đầu vào", icon: 'fas fa-book'}
    ]);
    this.getTestInputSchedule();


  },
  watch: {
    dialogFormVisible() {
      this.statusCreateTestInput = STATUS_CREATE;
    },
    statusCreateTestInput() {
      this.getTestInputSchedule();
    }
  },
  computed: {
    getUrlCopy() {
      if (this.customer.test_input_schedule.center_id === ECOACH) {
        return process.env.VUE_APP_TEST_INPUT_ECOACH + 'login?token=' + this.customer.test_input_schedule.uuid;
      }
      return process.env.VUE_APP_TEST_INPUT_IELTS + 'login?token=' + this.customer.test_input_schedule.uuid;
    },
    customerInfoCopy() {
      return `Đường liên kết tới bài kiểm tra đầu vào của khách hàng ${this.customer.test_input_schedule.name} - ${this.customer.test_input_schedule.phone} `;
    },
    showButtonDatLai() {
      if (this.customer.test_input_schedule.status == DA_TRA_KET_QUA || this.customer.test_input_schedule.status == HUY_LICH) {
        return true;
      }
      return false;
    },
    showBaiTestTracNghiem() {
      if (this.customer.test_input_schedule.status == HUY_LICH) {
        return false;
      }
      return true;
    },
    conditionIsOnlyGeneral() {
      return this.status === 'Đăng ký test general' || !this.customer?.test_input_schedule?.branch_id;
    },
  untilTestTime(){
      const now = moment().format('yyyy-MM-DD HH:mm');
      const startTest = moment(this.customer?.test_input_schedule?.schedule).format('yyyy-MM-DD HH:mm');
      return now > startTest;
    },
    ...mapGetters([
     'isPilot'
    ])
  },
  methods: {
    async getResultTests() {
      let test_input_schedule_id = this.customer.test_input_schedule.id
      let data = await this.$store.dispatch(GET_RESULT_EXAM, test_input_schedule_id ?? 0);
      this.suggestedCourse = data?.data?.course_suggest
      if (!data.error) {
        if (+this.customer?.test_input_schedule?.center_id !== this.Cambridge_Mentor_ID) {
          let keys = ["speaking", "writing", "listening", "vocabulary", "reading"];
          if (data.data?.vocabulary?.right_answers > 19) {
            keys = ["speaking", "writing", "listening", "reading"];
            this.testIELTS = true
          } else {
            keys = ["speaking", "vocabulary"];
          }
          let res = keys.map((obj) => {
            if (!data?.data?.hasOwnProperty(obj) || data.data[obj] == null) {
              return null;
            }
            return new ResultTest(TYPE_TEST_BY_KEY.get(obj), data.data[obj].right_answers ?? null, data.data[obj].total_correct ?? null, data.data[obj].avg_point ?? null, data.data[obj],data?.data);
          }).filter(d => d != null);
          this.resultTests = res;
          this.pointOverall = data?.data?.overall
        } else {
          this.resultTests = data?.data?.speaking.filter((e) => e.id != null);
          this.commentAndConclusion = data?.data?.speaking.filter((e) => e.id == null);
        }

      }
    },
    getTestInputSchedule() {
      this.$store.dispatch(GET_TEST_INPUT_SCHEDULE, this.$route.params.id).then((data) => {
        this.customer = data.data;
        this.checkConfirmSpeaking()
        this.status = data?.data?.status
        this.getResultTests()
        if (this.customer.test_input_schedule) {
          if (moment(this.customer.test_input_schedule.schedule).format('YYYY-MM-DD HH:mm:ss') < moment().format('YYYY-MM-DD HH:mm:ss') ||
              this.customer.test_input_schedule.status == HUY_LICH
          ) {
            this.showButtonHuyLichDoiLich = false
          } else {
            this.showButtonHuyLichDoiLich = true
          }
          this.urlTestInput = this.getUrlCopy;
        }
        if (!this.customer.test_input_schedule) {
          this.customer.test_input_schedule.name = '';
          this.customer.test_input_schedule.phone = '';
        }
        this.renderTestInput = true

      });
    },
    cancelTestInputSchedule() {
      Swal.fire({
        title: 'Bạn có chắc chắn muốn hủy lịch kiểm tra đầu vào?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xác nhận',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Hủy',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(CANCEL_TEST_INPUT_SCHEDULE, this.customer.test_input_schedule_id).then((res) => {
            if (res.error) {
              this.$message({type: 'error', message: res.error, showClose: true});
            } else {
              this.checkExistTestInputSchedule = true
              this.getTestInputSchedule()
              this.$message({type: 'success', message: `Hủy lịch test đầu vào thành công`, showClose: true});
            }
          }).catch((res) => {
            this.$message({type: 'success', message: res, showClose: true});
          });
        }
      });
    },
    openDialogTestInputSchedule(type) {
      if(type === 'them-lich-speaking'){
        this.dialog.title = "Đặt lịch test đầu vào";
      }else if (type === 'dat-lich') {
        this.checkExistTestInputSchedule = false;
        this.dialog.title = "Đặt lịch test đầu vào";
      } else {
        this.checkStatus();
        this.dialog.title = "Đổi lịch test đầu vào"
        this.checkExistTestInputSchedule = true
        this.statusCreateTestInput = STATUS_CREATE;
      }
      setTimeout(() => {
        this.dialogFormVisible = true;
      })
      console.log(this.checkExistTestInputSchedule)
    },
    checkStatus() {
      this.$store.dispatch(CHECK_STATUS, {
        id: this.customer.test_input_schedule.test_input_schedule_id
      }).then((res) => {
      }).catch((error) => {
        this.getTestInputSchedule();
        this.dialogFormVisible = false;
        if (error && error.data) {
          this.$message.error(error.data.message);
        }
      });
    },
    handleErrorCode(errorCode) {
      if (errorCode === ERROR_CODE_ASSIGN_FORM_EDIT) {
        this.testInput.ngay_hen = null;
        this.testInput.time = null;
      }
      if (errorCode === ERROR_CODE_UPDATE_IN_TASK_CANCEL) {
        this.closeDialog();
      }
    },
    submit() {
      this.renderTestInput = false
      this.testInput = _.merge(this.testInput, {id: this.customer.test_input_schedule_id})
      this.disableSubmit = true;
      if (this.checkExistTestInputSchedule || this.conditionIsOnlyGeneral) {
        this.$store.dispatch(UPDATE_TEST_INPUT_SCHEDULE, this.testInput).then((res) => {
          if (res.error) {
            this.$message({type: 'error', message: res.data.message, showClose: true});
            if (res.data.error_code === 1) {
              this.testInput.ngay_hen = null;
              this.testInput.time = null;
            }
          } else {
            this.statusCreateTestInput = STATUS_PENDING;
            this.updateTimeleft(res?.data?.created_at);
            this.idTaskCreating = res?.data?.id;
          }
        }).catch((res) => {
          this.$message({type: 'error', message: res.data.message, showClose: true});
          this.getTestInputSchedule();
          let errorCode = res.data.error_code;
          this.handleErrorCode(errorCode)
        }).finally(() => {
          setTimeout(() => {
            this.disableSubmit = false;
          }, 2000);
        });
      } else {
        this.$store.dispatch(CREATE_TEST_INPUT_SCHEDULE, this.testInput).then((res) => {
          if (res.error) {
            this.$message({type: 'error', message: res.error, showClose: true});
          } else {
            setTimeout(() => {
              this.statusCreateTestInput = STATUS_PENDING;
            })
            this.idTaskCreating = res?.data?.id;
          }
        }).catch((res) => {
          this.$message({type: 'error', message: res.data.message, showClose: true});
          if (res.data.error_code === 1) {
            this.testInput.ngay_hen = null;
            this.testInput.time = null;
          }
        }).finally(() => {
          setTimeout(() => {
            this.disableSubmit = false;
          }, 2000);
        });
      }
    },
    closeDialog() {
      this.dialogFormVisible = false
    },
    handleInfoTestInput(item) {
      this.testInput = item
    },
    copyUrl() {
      // Get the text field
      var copyText = document.getElementById("url");
      // Select the text field
      // copyText.select();
      // copyText.setSelectionRange(0, 99999); // For mobile devices
      // Copy the text inside the text field
      navigator.clipboard.writeText(this.customerInfoCopy + '\n' + copyText.value);
      // Alert the copied text
      this.$message.success("Sao chép đường dẫn thành công");
    },
    handleStatusCreateTestInput(status, customerId, successCreateData) {
      this.idCustomer = customerId;
      this.successCreateData = successCreateData
      this.statusCreateTestInput = status
    },
    updateTimeleft(created_at) {
      const secondTimeCreate = (new Date(created_at).getTime()) / 1000;
      const secondCurrent = (new Date().getTime()) / 1000;
      const timeRemaining = 60 - (secondCurrent - secondTimeCreate)
      if (timeRemaining > 0) {
        this.countTime = Math.max(timeRemaining, 60);
      }
    },
    formatDate(value) {
      return moment(value).format('HH:mm DD/MM/YYYY')
    },
    checkConfirmSpeaking(){
      let payload = {
        idTaskParent: this.customer?.test_input_schedule_id
      }
     this.$store.dispatch(IS_TIME_TO_CONFIRM,payload).then((data)=>{
       this.confirmSpeaking = true;
       const date = moment(data?.data?.date, 'YYYY-MM-DD HH:mm:ss');
       this.dataConfirmSpeaking = {
         name : data.data.name,
         time: date.format('HH:mm DD-MM-YYYY')
       }
     }).catch(()=>{
       this.confirmSpeaking = false;
     })
    },
    confirmTestSpeaking(isCome){
      let payload = {
        idTaskParent: this.customer?.test_input_schedule_id,
        isCome: isCome
      }
      this.$store.dispatch(CONFIRM_CUSTOMER_COME_TEST,payload).then((data)=>{
        this.confirmSpeaking = false;
        this.getTestInputSchedule();
        if(isCome){
          this.$message({
            message: 'Đã xác nhận thành công',
            type: 'success'
          });
          return;
        }
        this.$message('Đã hủy lịch thành công');
      })
    },
    isMobile() {
      return window.innerWidth <= 768;
    }
  }
}
</script>

<style>
.el-date-editor.el-input {
  width: 100% !important;
}

.status-test-input {
  font-size: 14px;
}

.swal2-confirm {
  background-color: #472F92 !important;
}

.el-select {
  /*display: inline!important;*/
  /*position: relative;*/
}

.content_result {
  max-height: 750px;
  overflow-y: auto;
  height: fit-content;

}
</style>